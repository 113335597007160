@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
body {
  height: 100vh;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
}

/* Modify the background color */
.navbar-custom {
  background-color: #111111;
}

/* Modify brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: #fff;
}

.navbar-custom .navbar-toggler-icon {
  /* background-image: url("/img/menu.svg"); */
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.btn{
  width: 300px;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

.far{
  font-size:2.2rem;
  color: #fff;
}

.fab{
  font-size:1.2rem;
  color: #111111;
  padding: 5px;
}

.fas{
  font-size:2.2rem;
  color: #fff;
}

.row {
  display: flex;
  justify-content:center;

}

.card{
  height:100%
}

.rounded-circle{
  
}

.card-title{
  color:#111111
}

.card-text{
  color:#c0a375
}

.navbar a {
  color: white;
}

.navbar a:hover {
  color: #c0a375;
}

header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 2em;
}

header .logo {
  color: #fff;
  font-weight: bold;
}

header ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header ul a {
  display: block;
  padding: 0 1em;
}

.social-header, .hero-design {
  display: none;
}

.hero {
  background: #fff;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  font-weight: bold;
  padding: 4em;
  margin-top: 0.8rem;
  font-size: 1.2rem;
}



.hero-design{
    width: 100%;
    margin-left: 20rem;
}

.content{
    margin-right: 10px;
}



.hero-design{
    float: right;
}
.hero-design img{
   width: 110%;
   border-radius: 5px;
}




.hero .content {
  font-weight: 800;
}



.btn:hover {
  color: #c0a375;
  background-color: #111111;
  font-weight: bold;
  border-color: #fff;
}

.meet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-weight: bold;
  color: #aaa;
  font-size: 1rem;
  align-items: center;
}

.meet span {
  margin-top: 1em;
  margin-right: .5em;
  
}

.meet .stagger1{
  align-items:center;
}

.finger{
  margin-left:35%;
}


.scroll {
  width: 30px;
  margin-top: 2em;
}

section {
  padding: 4em 2em;
  text-align: center;
}



.featured {
  position: relative;
}

.featured::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #fff;
  top: 0;
  left: 0;
  z-index: -1;
}

.subtitle {
  text-transform: uppercase;
  font-weight: bold;
  color: #c0a375;
  letter-spacing: .2em;
  font-size: .85em;
}

.featured-title {
  color: #111111;
  font-weight: bold;
  font-size: 1.3em;
  margin-top: -.4em;
  display: block;
}

.featured-desc {
  color: #111111;
  margin-bottom: 3em;
  font-size: .9em;
  line-height: 1.8em;
  font-weight: 500;
}

.skills {
  background: #fff;
  display: flex;
  justify-content:center;
}

.skills-container{
  display: flex;
  align-items: center;
  
}

.skillBox{
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items:center;
  padding:30px;
  flex-wrap:wrap;
  justify-content:center;
  width:100%;
  height:100%;
  
}

.skillBox li{
  margin: 1em 1em 0 0;
  flex: 1 1 10em;
  padding: 1em;
  width:100%
}

.skills-container ul li {
  background: #111111;
  padding: 2em;
  border-radius: 1em;
  margin-bottom: 1em;
}



.skills-container ul li .icon-container {
  height: 100px;
  /* display: -ms-grid;
  place-content: center;
  margin: 0 auto; */
}


.skills-container ul li .skill-title {
  font-weight: bold;
  color: #fff;
}

.skills-container ul li .featured-desc {
  margin-bottom: 2em;
  color: #fff;
}

.skills-container ul li .bi {
  color: #fff;
}

.pb, .pb-nav {
  background-color: #111111;
  color: white;
}



.bi-arrow-up-circle {
  color: #111111;
}

.pb-row-light, .pb-contact {
  background-color: #fff;
}

.portfolio {
  background: #F7F7F7;
}

.portfolio-container a img {
  border-radius: 1em;
  margin-bottom: 2em;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}



@media only screen and (max-width: 800px) {

    .hero{
        justify-content: center;
        display: flex;
    }

    .hero .content {
        background: #fff;
        color: #111111;
      }

      .skills-container{
        flex-direction: column;
        justify-content: center;
        align-items:center;
        
      }
      .skillBox{
        flex-direction: column;
        justify-content: center;
        width: max-content;
      }
}



@media only screen and (max-width: 800px) {
  
  .skills {
    margin-top: -5em;
    margin-bottom: -7em;
    padding-top: 7em;
    justify-content:center;
  }

  .skills-container{
    justify-content:center;
    
  }
  .skillBox{
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .skillBox li{
    width:100%
  }

  .portfolio {
    padding-top: 7em;
  }
  .portfolio-container img {
    margin-left: 2em;
  }
}

@media only screen and (min-width: 800px) {
  .featured, .portfolio {
    text-align: left;
  }
  .featured, .portfolio-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 40% auto;
        grid-template-columns: 40% auto;
  }
  .left, .portfolio-left {
    display: -ms-grid;
    display: grid;
    place-content: center;
  }
  .right {
    margin-left: 2em;
    margin-top: 1em;
  }

  
  

  .hero .content {
    background: #fff;
    color: #111111;
    padding: 6em 8em 6em 4em;
    text-align: left;
    justify-content: center;
  }



  .portfolio {
    padding-top: 7em;
  }
  .portfolio-container img {
    margin-left: 2em;
  }
}


@media only screen and (min-width: 1050px) {
  .hero {
    height: 90vh;
  }
  .nav-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 66% auto;
        grid-template-columns: 66% auto;
    background: unset;
  }
  .nav-container header {
    background: #111111;
  }
  header {
    padding: 2em 2em 2em 4em;
  }
  .social-header {
    padding: 2em 4em 2em 0;
  }
  section {
    padding: 4em;
  }
  .social-header {
    display: block;
  }
  .social-header ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 7em;
    float: right;
  }
  .social-header ul img {
    width: 20px;
  }
  .hero {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 66% auto;
        grid-template-columns: 66% auto;
    background: unset;
    padding: 0;
  }
  .hero .content {
    background: #fff;
    color: #111111;
    padding: 6em 8em 6em 4em;
    text-align: center;
    justify-content:center;
  }
   .content h1 {
    font-size: 3em;
    line-height: 1.2em;
    color: #111111;
  }
  


  .hero-design {
    display: unset;
    margin-left: -30%;
    margin-top: 15%;
    width: 70%;
  }
  .portfolio img {
    float: right;
    max-width: 500px;
  }
  
}

@media only screen and (min-width: 1250px) {
  header {
    padding: 2em 2em 
    2em 10em;
  }

    .social-header {
    padding: 2em 10em 2em 0;
  }

  .content{
      margin-right: 2rem;
  }

 

  section {
    padding: 10em 10em 4em 10em;
  }
  .hero .content {
    padding: 6em 8em 6em 10em;
  }
}

@media only screen and (min-width: 1550px) {
  header {
    padding: 2em 2em 2em 20em;
  }
  .social-header {
    padding: 2em 20em 2em 0;
  }
  section {
    padding: 6em 20em 4em 20em;
  }
  .hero .content {
    padding: 9em 20em 6em 20em;
  }
}

.circle {
  animation: circleAnim 1s infinite alternate-reverse;
}

@-webkit-keyframes circleAnim {
  from {
    -webkit-transform: translate(262px, 798px);
            transform: translate(262px, 798px);
    z-index: 5;
  }
  to {
    -webkit-transform: translate(262px, 830px);
            transform: translate(262px, 830px);
  }
}

@keyframes circleAnim {
  from {
    -webkit-transform: translate(262px, 798px);
            transform: translate(262px, 798px);
    z-index: 5;
  }
  to {
    -webkit-transform: translate(262px, 830px);
            transform: translate(262px, 830px);
  }
}
/*# sourceMappingURL=main.css.map */